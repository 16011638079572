
import React, {useState, useContext} from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Layout from './Layouts/Layout';
import { Card, CardBody, CardHeader, CardTitle, Row, Col, Button } from 'reactstrap';

const CardStateContext = React.createContext();

function AutoCard({ children }) {
  const state = useState(false);

  return <CardStateContext.Provider value={state}><Card className="mb-3">{children}</Card></CardStateContext.Provider>;
}

function AutoCardHeader({ children }) {
  const [ isOpen, setOpen ] = useContext(CardStateContext);

  return <CardHeader onClick={a => setOpen(!isOpen)} style={{ cursor: 'pointer' }}>
    <Row>
      <Col>
        {children}
      </Col>
      <Col md="auto" className="d-flex align-items-center">
        <i className={"feather icon-chevron-" + (isOpen ? "down" : 'right')}></i>
      </Col>
    </Row>
  </CardHeader>;
}

function AutoCardBody({ children }) {
  const [ isOpen ] = useContext(CardStateContext);

  return !isOpen ? null : <CardBody>{children}</CardBody>;
}

export default function Info() {
  const intl = useIntl();
  const email = intl.formatMessage({ id: 'SUPPORT.EMAIL' })

  return <Layout name={intl.formatMessage({ id: 'INFO.TITLE' })}>
    <FormattedMessage
      id="INFO.DESCRIBE"
      values={{
        1: text => <AutoCard>{text}</AutoCard>,
        2: text => <AutoCardHeader><b>{text}</b></AutoCardHeader>,
        5: text => <AutoCardBody>{text}</AutoCardBody>,
        3: text => <b>{text}</b>,
        4: text => <p>{text}</p>,
        br: <br />,
        email: <a href={"mailto:" + email}>{email}</a>
      }}
    />
  </Layout>;
}
