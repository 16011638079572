import React from 'react';
import './App.css';
import './assets/css/bootstrap.min.css';

import './assets/css/style.css';

import 'sweetalert2/dist/sweetalert2.css';
import 'font-awesome/css/font-awesome.css';

import AuthCommon from './Auth/Common';
import LayoutMain from './Layouts/Main';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect
} from "react-router-dom";
import { is_logged_in, set_hook, set_logged_in_changed } from './api';
import LanguageProvider from './lang';

import "nprogress/nprogress.css";
import NProgress from 'nprogress';

export default class extends React.Component {
  componentDidMount() {
    set_logged_in_changed(() => this.forceUpdate());
    set_hook(() => NProgress.start(), () => NProgress.done());
  }

  componentWillUnmount() {
    set_logged_in_changed(null);
  }

  render() {
    return (
      <LanguageProvider>
        <Router>
            { is_logged_in() ? <LayoutMain /> : <AuthCommon />}
        </Router>
      </LanguageProvider>
    );
  }
}
