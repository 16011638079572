
import React, { Component, useCallback } from 'react';
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { DndProvider, useDrag, useDrop } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { Col, Row } from 'reactstrap';
import LineTo from 'react-lineto';

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

function Drop({ children, className, type, id }) {
    const [{ canDrop, isOver }, drop] = useDrop({
        accept: type ? 'a' : 'b',
        drop: () => ({ id: id }),
        collect: (monitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
        }),
    });

    const isActive = canDrop && isOver;

    if (isActive) {
        className += ' active-drop';
    }
    
    return (<div ref={drop} className={className}>
        {children}
    </div>);
}

function Drag({ children, className, type, onDone, id }) {
    const [{ isDragging }, drag] = useDrag({
        item: { id: id, type: type ? 'a' : 'b' },
        end: (item, monitor) => {
            const dropResult = monitor.getDropResult();
            if (item && dropResult) {
                onDone(+item.id, +dropResult.id);
            }
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    const opacity = isDragging ? 0.4 : 1;

    return (<div ref={drag} className={className} style={{ opacity }}>
        {children}
    </div>);
}

function DragAndDrop({ type, id, children, className, onDone }) {
    return <Drop className={className} type={!type} id={id}>
        <Drag type={!!type} onDone={(a, i) => !!type ? onDone(a, i) : onDone(i, a)} id={id}>
            { children }
        </Drag>
    </Drop>
}

export function Provider(props) {
    return <DndProvider backend={HTML5Backend} {...props} />;
}

export function Matchable({ children, value, setValue }) {    
    var items = [...children];

    const onDone = useCallback(function(source, destination) {
        const newValue = (value || [...Array(items.length)])
            .map((a, i) => (i === source ? destination : destination === a ? null : a))
            .map(a => a === 0 ? 0 : (a || null));

        setValue(
            newValue
        );
    }, [value, setValue]);

    return <>
        <Col>
            { items.map((a, i) => <DragAndDrop key={i} id={i} type className={"a-q-"+i} onDone={onDone}>{a[0]}</DragAndDrop>) }
        </Col>
        <Col md="1" />
        <Col>
            { items.map((a, i) => <DragAndDrop key={i} id={i} className={"m-q-"+i} onDone={onDone}>{a[1]}</DragAndDrop>) }
        </Col>
        { value && value.map((a,i) => (a === 0 || a > 0) && <LineTo key={(a*100) + i} from={"a-q-" + i} to={"m-q-" + a} fromAnchor="100% 50%" toAnchor="0% 50%" delay={1} />) }
    </>;
}

export default class Orderable extends Component {
    onDragEnd(result) {
        if (!result.destination) {
        return;
        }

        this.props.onChange(reorder(
            this.props.value,
            result.source.index,
            result.destination.index
        ), result.source.index, result.destination.index);
    }

    render() {
        return <DragDropContext onDragEnd={this.onDragEnd.bind(this)}>
            <Droppable droppableId={this.props.id || 'random-draggable'}>
            {(provided, snapshot) => (
                <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                >
                {(this.props.value || []).map((value, i) =>
                    <Draggable key={value.id || -i} draggableId={(value.id || -i).toString()} index={i}>
                        {(provided, snapshot) => (
                            <div ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}>
                            { this.props.children(value, i) }
                        </div>)}
                    </Draggable>
                )}
                {provided.placeholder}
                </div>
            )}
            </Droppable>
        </DragDropContext>;
    }
}

const newItem = Symbol();

export class Orderable2 extends Component {
    onDragEnd(result) {
        if (!result.destination) {
            return;
        }

        if(!this.props.value)
            return null;

        this.props.onChange(reorder(
            this.props.value,
            result.source.index,
            result.destination.index
        ));
    }

    render() {
        return <DragDropContext onDragEnd={this.onDragEnd.bind(this)}>
            <Droppable droppableId={this.props.id || 'random-draggable'}>
            {(provided, snapshot) => (
                <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={{ 'background': snapshot.isDraggingOver ? 'lightblue' : 'inherit' }}
                >
                {(this.props.value || []).concat([newItem]).map((value, i) =>
                    <Draggable key={value.id || -i} draggableId={(value.id || -i).toString()} isDragDisabled={ value === newItem } index={i}>
                        {(provided, snapshot) => (
                            <div ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={{ 'background': snapshot.isDragging ? 'yellow' : 'inherit', ...provided.draggableProps.style }}>
                            { this.props.children(value === newItem ? null : value, i, {
                                onChange: (val) => this.props.onChange(value === newItem ? (this.props.value || []).concat([ val ]) : this.props.value.map(b => b === value ? val : b)),
                                onDelete: () => this.props.onChange(this.props.value.filter(a => a !== value))

                            }) }
                        </div>)}
                    </Draggable>
                )}
                {provided.placeholder}
                </div>
            )}
            </Droppable>
        </DragDropContext>;
    }
}