

import React from 'react';
import { Container, Row, Col, Card, CardBody, FormGroup, Input, CustomInput, Button, Form } from 'reactstrap';
import { Switch, Redirect } from 'react-router-dom';
import SimpleReactValidator from 'simple-react-validator';
import Validator from '../Common/Validator';
import Swal from 'sweetalert2';
import { log_in, is_logged_in, endpoint, free, exchange_token, client_id } from '../api';
import { Link } from 'react-router-dom';
import qs from 'query-string';
import { FormattedMessage, injectIntl } from 'react-intl';

export default injectIntl(class extends React.Component {
    state = { loading: false };

    constructor(props) {
        super(props);

        this.validator = new SimpleReactValidator({
            messages: {
                email: props.intl.formatMessage({ id: 'VALIDATION.EMAIL' }),
                default: props.intl.formatMessage({ id: 'VALIDATION.REQUIRED' })
            }
        });
    }

    async componentDidMount() {
        var params = qs.parse(this.props.location.search);
        if(params.code) {
            this.setState({ loading: true });
            try {
                await exchange_token(params.code);
            } finally {
                this.setState({ loading: false });
            }
        }
    }

    async handleSubmit(e) {
        e.preventDefault();

        if (!this.validator.allValid()) {
            this.validator.showMessages();
            this.forceUpdate();
            return ;
        }

        this.setState({ loading: true });
        try {
            await log_in(this.state.email, this.state.password);
            this.forceUpdate();
        } catch(e) {
            this.validator.hideMessages();
            
            Swal.fire(
                this.props.intl.formatMessage({ id: 'GENERAL.ERROR' }),
                this.props.intl.formatMessage({ id: 'AUTH.INVALID_CREDIENTALS' }),
                'error'
            );
        } finally {
            this.setState({ loading: false, password: null });
        }
    }

    render() {
        const { intl } = this.props;
        const { validator } = this;

        if(is_logged_in()) {
            return <Redirect to="/" />
        }

        return <>
            <h4 className="text-primary my-4"><FormattedMessage id="AUTH.LOGIN" /></h4>
            <Form onSubmit={this.handleSubmit.bind(this)}>
                <FormGroup>
                    <Validator name="email" value={this.state.email} type="required|email" validator={validator}>
                        <Input
                            type="text"
                            value={this.state.email || ""}
                            onChange={a => this.setState({ email: a.currentTarget.value })}
                            placeholder={intl.formatMessage({ id: 'AUTH.EMAIL' })}
                            disabled={this.state.loading}
                        />
                    </Validator>
                </FormGroup>
                <FormGroup>
                    <Validator name="password" value={this.state.password} type="required" validator={validator}>
                        <Input
                            type="password"
                            value={this.state.password || ""}
                            onChange={a => this.setState({ password: a.currentTarget.value })}
                            placeholder={intl.formatMessage({ id: 'AUTH.PASSWORD' })}
                            disabled={this.state.loading}
                            autoComplete="current-password"
                        />
                    </Validator>
                </FormGroup>
                <Row form className="mb-3">
                    <Col sm="6">
                        <CustomInput disabled={this.state.loading} className="text-left font-14" type="checkbox" id="rememberme" label={intl.formatMessage({ id: 'AUTH.REMEMBER_ME' })} />
                    </Col>
                    <Col sm="6">
                        <div className="forgot-psw">
                            <Link className="font-14" to="/auth/forgot"><FormattedMessage id="AUTH.FORGET_PASSWORD" /></Link>
                        </div>
                    </Col>
                </Row>
                <Button block size="lg" color="success" className="font-18" disabled={this.state.loading}>
                    { (this.state.loading && <i className="mdi mdi-loading mdi-spin mr-1" />) || <i className="mdi mdi-login mr-1" />}
                    <FormattedMessage id="AUTH.COMPLETE_LOGIN" />
                </Button>
            </Form>
            <div className="login-or">
                <h6 className="text-muted"><FormattedMessage id="AUTH.OR" /></h6>
            </div>
            <div className="button-list text-center">
                <a href={endpoint + 'login/redirect/facebook?callback_url=' + encodeURIComponent(window.location.origin + '/auth/login') + '&client_id=' + client_id} className="btn btn-primary-rgba font-18"><i className="mdi mdi-facebook mr-2" /> Facebook</a>
                <a href={endpoint + 'login/redirect/google?callback_url=' + encodeURIComponent(window.location.origin + '/auth/login') + '&client_id=' + client_id} className="btn btn-danger-rgba font-18"><i className="mdi mdi-google mr-2" /> Google</a>
            </div>
            <p className="mb-0 mt-3">
                <FormattedMessage
                    id="AUTH.REGISTER"
                    values={{
                        "1": text => <Link to="/auth/register">{text}</Link>
                    }}
                />
            </p>
        </>;
    }
});
