
import React from 'react';
import { Container, Button, TabContent, TabPane, Nav, NavLink, UncontrolledTooltip, Tooltip  } from 'reactstrap';

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    NavLink as NLink,
    Redirect
  } from "react-router-dom";
import { logout, tokenized, wrap } from '../api';
import { FormattedMessage, injectIntl } from 'react-intl';
import { TrackContext } from '../Track';

export default injectIntl(class extends React.Component {
    state = { tab: 2 };

    render() {
        const { intl } = this.props;

        return <div className="leftbar">
        <div className="sidebar">       
            <div className="navigationbar">
                <div className="vertical-menu-icon">
                    <Nav vertical pills className="flex-column">
                        <div className="logobar">
                            <a href="index.html" className="logo-small"><img style={{ padding: '0 10px' }} src={require("../assets/images/logo.png").default} className="img-fluid" alt="logo" /></a>
                        </div>
                        <ToolTipNavLink
                            active={this.state.tab == 1}
                            onClick={a => this.setState({ tab: 1 })}
                            src={require("../assets/images/svg-icon/frontend.svg").default}
                            title={intl.formatMessage({ id: 'HOME' })} />
                        <ToolTipNavLink
                            active={this.state.tab == 2}
                            onClick={a => this.setState({ tab: 2 })}
                            src={require("../assets/images/svg-icon/ui-kits.svg").default}
                            title={intl.formatMessage({ id: 'MODULES' })} />
                        <ToolTipNavLink
                            active={this.state.tab == 3}
                            onClick={a => this.setState({ tab: 3 })}
                            src={require("../assets/images/svg-icon/forms.svg").default}
                            title={intl.formatMessage({ id: 'Certificate' })} />
                            {/*
                        <ToolTipNavLink
                            active={this.state.tab == 3}
                            onClick={a => this.setState({ tab: 3 })}
                            src={require("../assets/images/svg-icon/basic.svg").default}
                            title={intl.formatMessage({ id: 'PROJECTS' })} />
                        <ToolTipNavLink
                            active={this.state.tab == 4}
                            onClick={a => this.setState({ tab: 4 })}
                            src={require("../assets/images/svg-icon/reports.svg").default}
                            title={intl.formatMessage({ id: 'REPORTS' })} />
                            */}
                    </Nav>
                </div>
                <div className="vertical-menu-detail">
                    <div className="logobar">
                        <Link to="/" className="logo logo-large"><img src={require("../assets/logo.png").default} className="img-fluid" alt="logo" /></Link>
                    </div>
                    <TabContent activeTab={this.state.tab}>
                        <TabPane tabId={1}>
                            <ul className="vertical-menu">
                                <li><h5 className="menu-title"><FormattedMessage id="HOME" /></h5></li> 
                                <li><Link to="/"><img src={require("../assets/images/svg-icon/dashboard.svg").default} className="img-fluid" alt="dashboard" /><FormattedMessage id="DASHBOARD" /></Link></li>
                                <li><Link to="/profile"><img src={require("../assets/images/svg-icon/reports.svg").default} className="img-fluid" alt="projects" /><FormattedMessage id="PROFILE" /></Link></li>
                                <li><Link to="/certificate"><img src={require("../assets/images/svg-icon/forms.svg").default} className="img-fluid" alt="certificate" /><FormattedMessage id="CERTIFICATE" /></Link></li>
                            </ul>
                        </TabPane>
                        <TabPane tabId={2}>
                            <ModulesMenu id={intl.formatMessage({ id: 'MENU_ID' })} />
                        </TabPane>        
                        <TabPane tabId={3}>
                            <ul className="vertical-menu">
                                <li><h5 className="menu-title"><FormattedMessage id="CERTIFICATE" /></h5></li>
                                <li><Link to="/certificate"><img src={require("../assets/images/svg-icon/forms.svg").default} className="img-fluid" alt="certificate" /><FormattedMessage id="CERTIFICATE" /></Link></li>
                            </ul>
                        </TabPane>                       
                    </TabContent>
                </div>
            </div>
        </div>
    </div>;
    }
});

class ModulesMenu extends React.Component {
    static contextType = TrackContext;
    state = { open: false };

    async componentDidMount() {
        var category = await tokenized.get('/category/' + this.props.id);

        this.setState({ category: category.data });
    }

    componentDidUpdate(prevProps) {
        if (this.props.id !== prevProps.id) {
            return this.componentDidMount();
        }
    }

    handleClick(id, e) {
        e.preventDefault();
        this.setState({ open: this.state.open === id ? null : id });
    }

    renderSubmenus() {
        return ((this.state.category || this.props.category).childerens || [])
            .map(category => <InnerNav
                key={category.id}
                id={category.id}
                category={category}
                isOpen={ this.state.open === category.id }
                toggle={a => this.setState({ open: this.state.open === category.id ? false : category.id })}
            />);
    }

    render() {
        if(!this.state.category) {
            return <>Loading...</>;
        }

        return <ul className="vertical-menu">
            <li><h5 className="menu-title">{ this.state.category.name }</h5></li>
            <li>
                <NLink activeClassName="active" to={"/info"}>
                    <b><FormattedMessage id="INFO.MENU" /></b>
                </NLink>
            </li>
            {this.renderSubmenus()}
        </ul>
    }
}

class InnerNav extends ModulesMenu {
    static contextType = TrackContext;

    async componentDidMount() {
        if (this.props.category.type === 1) {
            var category = await tokenized.get('/category/' + this.props.id);

            this.setState({ category: category.data });
        }
    }

    handleClick(e) {
        e.preventDefault();
        this.props.toggle();
    }

    render() {
        const category = this.state.category || this.props.category;
        const {  isOpen, toggle } = this.props;

        if (category.type === 2) {
            const className = this.context.completed[category.id] ? 'is-completed' : this.context.next_category && this.context.next_category.id == category.id ? 'is-pending' : 'is-disabled';

            return <li>
                <NLink activeClassName="active" className={className} to={"/category/" + category.id}>
                    { category.name }
                </NLink>
            </li>
        }

        const className = this.context.completed[category.id] ? 'is-completed' : '';

        return <li className={className + " " + ((isOpen && "active") || "")}>
            <a href="#" onClick={this.handleClick.bind(this)}>
                { category.icon && <img src={wrap(category.icon.access_url)} className="img-fluid" alt={category.name} /> }
                <span>{ category.name }</span><i className={"feather icon-chevron-right"}></i>
            </a>
            <ul className={"vertical-submenu"}>
                {this.renderSubmenus()}
            </ul>
        </li>;
    }
}

class ToolTipNavLink extends React.Component {
    render() {
        return <>
            <NavLink
                id={"_" + this.props.title}
                active={this.props.active}
                onClick={this.props.onClick}>
                <img src={this.props.src} className="img-fluid" title={this.props.title}
            />
            </NavLink>
            <UncontrolledTooltip placement="right" target={"_" + this.props.title}>
                { this.props.title }
            </UncontrolledTooltip>
        </>;
    }
}
