
import React from 'react';
import { FormattedMessage } from 'react-intl';

export default class extends React.Component {
    render() {
        return null;
        
        return <div class="footerbar">
            <footer class="footer">
                <p class="mb-0"><FormattedMessage id="COPYRIGHT" values={{ year: 2020 }} /></p>
            </footer>
        </div>;
    }
}
