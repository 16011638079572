import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Alert } from "reactstrap";

export default function Loading() {
  return <Alert color="warning">
    <i className="fa fa-spinner fa-spin" />{' '}{' '}
    <b><FormattedMessage id="PAGE_LOADING" /></b>
  </Alert>;
}
