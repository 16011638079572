import React, { useState } from 'react';
import { useContext } from 'react';
import { useEffect } from 'react';
import { IntlProvider } from 'react-intl';
import { DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { AutoDropDown } from '../Layouts/TopBar';

const defaultLanguage = require('./en.json');

function messages(lang) { return {...defaultLanguage, ...lang}; };

export const languageList = [
    {
        code: 'en',
        name: 'English',
        flagCode: 'us',
        flag: require('svg-country-flags/svg/us.svg').default,
        messages: defaultLanguage
    },
    {
        code: 'no',
        name: 'Norwegian',
        flagCode: 'no',
        flag: require('svg-country-flags/svg/no.svg').default,
        messages: messages(require('./no.json'))
    },
    {
        code: 'es',
        name: 'Spanish',
        flagCode: 'es',
        flag: require('svg-country-flags/svg/es.svg').default,
        messages: messages(require('./es.json'))
    },
    {
        code: 'ro',
        name: 'Romanian',
        flagCode: 'ro',
        flag: require('svg-country-flags/svg/ro.svg').default,
        messages: messages(require('./ro.json'))
    },
    {
        code: 'pl',
        name: 'Polish',
        flagCode: 'pl',
        flag: require('svg-country-flags/svg/pl.svg').default,
        messages: messages(require('./pl.json'))
    }
];

const languages = languageList.reduce((a, b) => (a[b.code] = b) && a, {});
export const LanguageContext = React.createContext();

export default function LanguageProvider(props) {
    const [ lang, setLang ] = useState(() => languages[localStorage.getItem('locale') || 'en'] || languages.en);

    return <LanguageContext.Provider value={{ lang, setLang }}>
        <IntlProvider messages={lang.messages} locale={lang.code} defaultLocale="en" {...props} />
    </LanguageContext.Provider>;
}

export function LanguageSwitcherBasic({ menu }) {
    const { lang, setLang } = useContext(LanguageContext);

    const changeLang = (lang, e) => {
        e.preventDefault();
        setLang(lang);
        localStorage.setItem('locale', lang.code);
    };

    if (menu) {
        return <li className="list-inline-item mr-3">
            <div className="languagebar">
                <AutoDropDown>
                    <DropdownToggle tag="a">
                        <span className="live-icon"><img className="flag mr-1" src={lang.flag} alt={lang.name} /> { lang.name }</span>
                        <span className="feather icon-chevron-down live-icon"></span>
                    </DropdownToggle>
                    <DropdownMenu right>
                        {languageList.map(a =>
                            <DropdownItem tag="a" href="#" onClick={changeLang.bind(this, a)} className="dropdown-item"><img className="flag mr-1" src={a.flag} alt={a.name} />{ a.name }</DropdownItem>
                        )}
                    </DropdownMenu>
                </AutoDropDown>
            </div>                             
        </li>;
    }

    return <ul className="lang-switch">
        {languageList.map(a => <li key={a.code}>
            <a href="#" onClick={changeLang.bind(this, a)} className={a === lang ? 'active' : ''}>
                <img src={a.flag} alt={a.name} />
                <span>{a.name}</span>
            </a>
        </li>)}
    </ul>;
}

