

import React from 'react';
import { Container, Row, Col, Card, CardBody, FormGroup, Input, CustomInput, Button, Form, Alert } from 'reactstrap';

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    Redirect
  } from "react-router-dom";

  
import Login from './Login';
import Register from './Register';
import ForgetPassword from './ForgetPassword';
import { LanguageSwitcherBasic } from '../lang';
import { AutoPlayHls } from '../Module/Video';
import { FormattedMessage } from 'react-intl';

export default class extends React.Component {
    render() {
        return <Switch>
            <Route exact path="/" component={IntroPage} />
            <Route path="/auth" component={AuthPages} />
            <Redirect to="/" />
        </Switch>
    }
}

class IntroPage extends React.Component {
    render() {
        return <div className="intro-box">
            <div>
                <Row className="mb-5">
                    <Col md="5" className="padding-div">
                        <div>
                            <img src={require('../assets/logo-intro.png').default} alt="SPIN-VET" />
                        </div>
                        <p>
                            <FormattedMessage id="INTRO.TAGLINE" values={{ linebreak: <br /> }} />
                        </p>
                        <div>
                            <p>
                                <Link className="btn btn-primary" to="/auth/login"><i className={"feather icon-chevron-right"} /> <FormattedMessage id="INTRO.START" /></Link>
                            </p>
                            <p>
                                <a className="small-text" href="https://spin-vet.com/" target="_blank"><FormattedMessage id="INTRO.ABOUT" /></a>
                            </p>
                        </div>
                        <div>
                            <div>
                                <img className="small" src={require('../assets/erasmusplus.png').default} />
                                <img className="small" src={require('../assets/euflag.png').default} />
                            </div>
                        </div>
                    </Col>
                    <Col md="7">
                        <AutoPlayHls
                            muted
                            link="https://player.vimeo.com/external/463780863.m3u8?s=273e4a3ba1866896768baed956da00e597718f6a"
                            width="100%"
                            height="100%"
                        />
                    </Col>
                </Row>
                <LanguageSwitcherBasic />
            </div>
        </div>;
    }
}

class AuthPages extends React.Component {
    render() {
        return <div id="containerbar" className="containerbar authenticate-bg">
            <Container>
                <div className="auth-box login-box">
                    <Row noGutters className="align-items-center justify-content-center">
                        <Col md="6" lg="5">
                            <div className="auth-box-right">
                                <Card>
                                    <CardBody>
                                        <div className="form-head">
                                            <a href="index.html" className="logo"><img src={require('../assets/logo.png').default} className="img-fluid" alt="logo" /></a>
                                        </div>
                                        <Container>
                                            <Switch>
                                                <Route exact path="/auth/login" component={Login} />
                                                <Route exact path="/auth/register" component={Register} />
                                                <Route exact path="/auth/forgot" component={ForgetPassword} />
                                                <Redirect to="/auth/login" />
                                            </Switch>
                                        </Container>
                                    </CardBody>
                                </Card>

                                <LanguageSwitcherBasic />
                            </div>
                        </Col>
                    </Row>
                </div>
            </Container>
        </div>;
    }
}
