
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { Col, Row } from 'reactstrap';

export default function Layout({ children, name, items }) {
  return <div className="flex-full">
    <Breadcrumb name={name} items={items || []} />
    <div className="contentbar flex-full">
        { children }
    </div>
  </div>;
}

export function Breadcrumb({ name, items }) {
  return <div className="breadcrumbbar">
    <Row className="align-items-center">
        <Col md="8" lg="8">
            <h4 className="page-title">{ name }</h4>
            <div className="breadcrumb-list">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/"><FormattedMessage id="HOME" /></Link></li>
                    { items.map((item, i) => <li key={i} className="breadcrumb-item">{ item }</li>)}
                </ol>
            </div>
        </Col>
    </Row>
  </div>;
}
