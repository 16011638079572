
import React from 'react';
import { Container, Row, Col, Card, CardBody, FormGroup, Input, CustomInput, Button, Form } from 'reactstrap';
import { Switch, Redirect, Link } from 'react-router-dom';
import SimpleReactValidator from 'simple-react-validator';
import Validator from '../Common/Validator';
import Swal from 'sweetalert2';
import { log_in, is_logged_in, free } from '../api';
import { FormattedMessage, injectIntl } from 'react-intl';

export default injectIntl(class extends React.Component {
    state = { loading: false, step: 1 };

    constructor(props) {
        super(props);

        this.validator = new SimpleReactValidator({
            messages: {
                email: props.intl.formatMessage({ id: 'VALIDATION.EMAIL' }),
                default: props.intl.formatMessage({ id: 'VALIDATION.REQUIRED' }),
                in: props.intl.formatMessage({ id: 'VALIDATION.PASSWORD2' }),
                min: props.intl.formatMessage({ id: 'VALIDATION.MIN' })
            }
        });
    }

    handleRetry(e) {
        e.preventDefault();

        this.setState({ submitted: false, email: null });
    }

    async handleSubmit(e) {
        e.preventDefault();

        if (!this.validator.allValid()) {
            this.validator.showMessages();
            this.forceUpdate();
            return ;
        }

        this.setState({ loading: true });
        try {
            switch(this.state.step) {
                case 1:
                    let signature = (await free.post('/reset', { email: this.state.email })).data;
                    this.setState({ step: 2, signature });
                break;
                case 2:
                    await free.get(this.state.signature, { params: { code: this.state.code } });
                    this.setState({ step: 3, password: null, password2: null });
                break;
                case 3:
                    await free.post(this.state.signature, { email: this.state.email, password: this.state.password }, { params: { code: this.state.code } });
                    await log_in(this.state.email, this.state.password);

                    this.setState({ step: 1, password: null, password2: null });
                break;
            }
        } catch(e) {
            Swal.fire(
                this.props.intl.formatMessage({ id: 'GENERAL.ERROR' }),
                this.props.intl.formatMessage({ id: 'AUTH.INVALID_CODE' }),
                'error'
            );
        } finally {
            this.setState({ loading: false });
        }
    }

    render() {
        const { intl } = this.props;
        const { validator } = this;

        if(is_logged_in()) {
            return <Redirect to="/" />
        }

        return <>
            <h4 className="text-primary my-4"><FormattedMessage id="AUTH.FORGET_PASSWORD_TITLE" /></h4>
            <Form onSubmit={this.handleSubmit.bind(this)}>
                <FormGroup>
                    <Validator name="email" value={this.state.email} type="required|email" validator={validator}>
                        <Input
                            type="text"
                            value={this.state.email || ""}
                            onChange={a => this.setState({ email: a.currentTarget.value })}
                            placeholder={intl.formatMessage({ id: 'AUTH.EMAIL' })}
                            disabled={this.state.loading || this.state.submitted}
                        />
                    </Validator>
                </FormGroup>
                { this.state.step == 2 &&
                    <>
                        <FormGroup>
                            <Validator name="code" value={this.state.code} type="required" validator={validator}>
                                <Input
                                    type="text"
                                    value={this.state.code || ""}
                                    onChange={a => this.setState({ code: a.currentTarget.value })}
                                    placeholder={intl.formatMessage({ id: 'AUTH.CODE' })}
                                    disabled={this.state.loading}
                                />
                            </Validator>
                        </FormGroup>
                        <p className="text-left font-14">
                            <FormattedMessage
                                id="AUTH.ENTER_CODE"
                                values={{
                                    email: <b>{this.state.email}</b>
                                }}
                            />
                        </p>
                        <p className="text-left font-14">
                            <FormattedMessage
                                id="AUTH.USE_DIFFERENT_EMAIL"
                                values={{
                                    "1": text => <a href="#" onClick={this.handleRetry.bind(this)}>{text}</a>
                                }}
                            />
                        </p>
                    </>}
                { this.state.step == 3 &&
                    <>
                        <FormGroup>
                            <Validator name="password" value={this.state.password} type="required|min:6" validator={validator}>
                                <Input
                                    type="password"
                                    value={this.state.password || ""}
                                    onChange={a => this.setState({ password: a.currentTarget.value })}
                                    placeholder={intl.formatMessage({ id: 'AUTH.NEW_PASSWORD' })}
                                    disabled={this.state.loading}
                                    autoComplete="new-password"
                                />
                            </Validator>
                        </FormGroup>
                        <FormGroup>
                            <Validator name="password2" value={this.state.password2} type={"required|in:" + this.state.password} validator={validator}>
                                <Input
                                    type="password"
                                    value={this.state.password2 || ""}
                                    onChange={a => this.setState({ password2: a.currentTarget.value })}
                                    placeholder={intl.formatMessage({ id: 'AUTH.NEW_PASSWORD2' })}
                                    disabled={this.state.loading}
                                    autoComplete="new-password"
                                />
                            </Validator>
                        </FormGroup>
                    </>}
                <Row form className="mb-3">
                    <Col sm="12">
                        <div className="forgot-psw">
                            <Link to="/auth/login" className="font-14" href="#"><FormattedMessage id="AUTH.LOGIN_INSTEAD" /></Link>
                        </div>
                    </Col>
                </Row>
                <Button block size="lg" color="success" className="font-18" disabled={this.state.loading}>
                    { (this.state.loading && <i className="mdi mdi-loading mdi-spin mr-1" />) || <i className="mdi mdi-login mr-1" />}
                    <FormattedMessage id="AUTH.CONTINUE" />
                </Button>
            </Form>
        </>;
    }
});
