

import React from 'react';
import { FormattedMessage, injectIntl, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { Row, Col, Container, Button, TabContent, TabPane, Nav, NavLink, Card, CardHeader, CardBody, CardFooter, Progress, Alert } from 'reactstrap';

import { tokenized } from './api';
import Footer from './Layouts/Footer';
import Video, { AutoPlayHls } from './Module/Video';
import { Avatar } from './Common/UserInfo';
import Loading from './Common/Loading';
import Layout, { Breadcrumb } from './Layouts/Layout';

class Stars extends React.Component {
    render() {
        const { value } = this.props;

        return <div className="button-list mt-4 mb-4">
            <i className={"feather icon-star" + (value >= 1 ? ' star-active' : '') } />
            <i className={"feather icon-star" + (value >= 2 ? ' star-active' : '') } />
            <i className={"feather icon-star" + (value >= 3 ? ' star-active' : '') } />
            <i className={"feather icon-star" + (value >= 4 ? ' star-active' : '') } />
            <i className={"feather icon-star" + (value >= 5 ? ' star-active' : '') } />
        </div>;
    }
}

const colors = [ "primary", 'info', 'warning', 'danger' ];

function StatBox({ name, value, margin, color }) {
    return <Card style={{ flex: 1, marginBottom: margin ? '10px' : undefined }} color={color}>
        <CardBody className="d-flex justify-content-center">
            <Row className="align-self-center">
                <Col className="text-center">
                    <h3>{ value }</h3>
                    <p className="font-16 mb-0"><FormattedMessage id={name} /></p>
                </Col>
            </Row>
        </CardBody>
    </Card>
}

export default function DashboardFnc() {
    const intl = useIntl();
    return <Dashboard key={intl.formatMessage({ id: 'MENU_ID' })} />
}

const Dashboard = injectIntl(class extends React.Component {
    state = {};

    async componentDidMount() {
        var [ profile, stats, categories ] = await Promise.all([
            tokenized.get("/user/me"),
            tokenized.get("/stats/overview/" + this.props.intl.formatMessage({ id: 'MENU_ID' })),
            tokenized.get("/category/" + this.props.intl.formatMessage({ id: 'MENU_ID' }))
        ]);

        const categoryStats = await Promise.all(categories.data.childerens
            .map(a => tokenized.get("/stats/overview/" + a.id).then(b => b.data)));

        var stats2 = categories.data.childerens.map((a, i) => ({ category: a, stats: categoryStats[i] }));
        console.log(stats2);
        this.setState({ profile: profile.data, stats: stats.data, categories: stats2 });
    }

    render() {
        const { profile, stats, categories } = this.state;

        if (!profile || !stats)
            return <Layout name={<FormattedMessage id="DASHBOARD" />} items={[<FormattedMessage id="DASHBOARD" />]}>
                <Loading />
            </Layout>;

        return <div className="d-flex flex-column" style={{ flexGrow: 1 }}>
            <div className="breadcrumbbar">
                <Row className="align-items-center">
                    <Col md="8" lg="8">
                        <h4 className="page-title"><FormattedMessage id="HOME" /></h4>
                        <div className="breadcrumb-list">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item active"><FormattedMessage id="DASHBOARD" /></li>
                            </ol>
                        </div>
                    </Col>
                </Row>
            </div>
            <div className="contentbar flex-full">
                <Row className="flex-full flex-row">
                    
                <Col sm="3" className="d-flex">
                        { this.state.profile && <Card style={{ flex: '1' }}>
                            <CardHeader>                   
                                <h5 className="card-title text-center mb-0"><FormattedMessage id="DASHBOARD.PROFILE" /></h5>
                            </CardHeader>                            
                            <CardBody className="text-center d-flex flex-column justify-content-between">
                                <Avatar index={this.state.profile.avatar_index} className="rounded-circle" style={{ width: '35%', margin: '0 auto' }} />
                                <div className="mt-3">
                                    <h5>{this.state.profile.name}</h5>
                                    <p>{this.state.profile.email}</p>
                                </div>
                                <p><span className="badge badge-success-inverse font-lg">{ (profile.badge && profile.badge.name) || 'New member' }</span></p>
                                <Stars value={ (profile.badge && profile.badge.stars) || 0 } />
                            </CardBody>
                            <CardFooter className="text-center">
                                <Row>
                                    <Col size="6" className="border-right">
                                        <h4>{ stats.completed_modules }</h4>
                                        <p className="my-2"><FormattedMessage id="DASHBOARD.COMPLETED_TASKS" /></p>
                                    </Col>
                                    <Col size="6">
                                        <h4>{ stats.total_modules }</h4>
                                        <p className="my-2"><FormattedMessage id="DASHBOARD.TOTAL_TASKS" /></p>
                                    </Col>
                                </Row>
                            </CardFooter>
                        </Card>}
                    </Col>
                    <Col sm="6" className="d-flex flex-column">
                        <Card style={{ flex: '1' }}>
                            <CardBody className="d-flex justify-content-center video-full-intro">
                                <div className="sixteen-nine">
                                    <div>
                                        { stats.last_completed ? 
                                            <AutoPlayHls muted link={stats.last_completed.dashboard_video.hls} />
                                                : <img className="img-fluid" style={{width: '100%', objectFit: 'cover' }} src={require('./assets/step.png').default} /> }
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col sm="3" className="d-flex flex-column justify-content-between">
                        <StatBox name="DASHBOARD.TOTAL_MEMBERS" value={stats.total_members} margin />
                        <StatBox name="DASHBOARD.YOUR_SCORE" value={stats.score} margin />
                        <StatBox name="DASHBOARD.YOUR_RANK" value={stats.rank} margin={!!stats.next_category} />
                        { stats.next_category && <StatBox name="DASHBOARD.NEXT_TASK" color="danger" value={<Link className="text-underline" to={"/category/" + stats.next_category.id}>{(stats.next_category.parent && stats.next_category.parent.name) || ''} / {stats.next_category.name}</Link>} /> }
                    </Col>
                </Row>

                <Card className="mt-3">
                    <CardBody>
                        <div className="progress-bar-info">
                        {
                                categories
                                    .map((a, i) => <div style={{ flexGrow: '1' }}>
                                        <p><b>{ a.category.name }</b></p>

                                        <p>
                                            <Progress multi style={{width:'100%'}}>
                                                <Progress bar color={colors[i%colors.length]} value={(a.stats.completed_modules / a.stats.total_modules) * 100} />
                                            </Progress>
                                        </p>

                                        <p>{ (a.stats.completed_modules / a.stats.total_modules * 100).toFixed(0) }%</p>
                                    
                                        <p>{ a.stats.score } / { a.stats.total_score }</p>
                                    </div>)
                            }
                        </div>
                    </CardBody>
                </Card>

                <Footer />
        </div>
    </div>;
    }
});
