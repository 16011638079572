
import React from 'react';
import { Container, Button } from 'reactstrap';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import Dashboard from './Dashboard';
import Category from './Category';
import UserInfo from './Common/UserInfo';
import Profile from './Pages/Profile';
import Certificate from './Certificate';
import Info from './Info';

export default class extends React.Component {
    render() {
        return <Switch>
            <Redirect from="/auth" to="/" />
            <Route path="/category/:id" component={Category} />
            <Route path="/profile" component={Profile} />
            <Route path="/certificate" component={Certificate} />
            <Route path="/info" component={Info} />
            <Route path="/" component={Dashboard} />
        </Switch>;
    }
}
