
import React from 'react';
import { Card, CardBody } from 'reactstrap';

import Plyr from 'plyr';
import 'plyr/dist/plyr.css';

export default class extends React.Component {
    render() {
        return <YouTubePlayer key={this.props.url} url={this.props.url} />;
    }
}

class YouTubePlayer extends React.Component {
    constructor(props) {
        super(props);
        this.video = React.createRef();
    }

    componentDidMount() {
        this.plyr = new Plyr(this.video.current);
    }

    componentWillUnmount() {
        this.plyr.destroy();
    }

    render() {
        if (!this.props.url)
            return null;
            
        return <div ref={this.video} data-plyr-provider="youtube" data-plyr-embed-id={this.props.url.match(/v=(.*)/)[1]} />
    }
}