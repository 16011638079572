
import React from 'react';
import { Container, Button, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    Redirect,
    NavLink
  } from "react-router-dom";
import { logout, tokenized } from '../api';
import { UserContext } from '../Layouts/Main';
import LeftBar from './LeftBar';
import { FormattedMessage } from 'react-intl';
import { useContext } from 'react';
import { useCallback } from 'react';
import { Avatar } from '../Common/UserInfo';
import { LanguageSwitcherBasic } from '../lang';

export default function TopBar(props) {
    const user = useContext(UserContext);

    return <div className="topbar">
        <div className="row align-items-center">
            <div className="col-md-12 align-self-center">
                <div className="infobar">
                    <ul className="list-inline mb-0">
                        <LanguageSwitcherBasic menu />
                        {/*
                        <li className="list-inline-item mr-3">
                            <div className="languagebar">
                                <AutoDropDown>
                                    <DropdownToggle tag="a">
                                        <span className="live-icon">EN</span>
                                        <span className="feather icon-chevron-down live-icon"></span>
                                    </DropdownToggle>
                                    <DropdownMenu right>
                                        <DropdownItem tag="a" className="dropdown-item"><i className="flag flag-icon-us flag-icon-squared"></i> English</DropdownItem>
                                    </DropdownMenu>
                                </AutoDropDown>
                            </div>                             
                        </li>
                        */}
                        <li className="list-inline-item">
                            <div className="profilebar">
                                <AutoDropDown>
                                <DropdownToggle tag="a">
                                    <Avatar className="img-fluid" index={user.avatar_index} />
                                    <span className="live-icon">{ user.name }</span>
                                    <span className="feather icon-chevron-down live-icon"></span>
                                    </DropdownToggle>
                                    <DropdownMenu right>
                                        <DropdownItem tag="div">
                                            <div className="profilename">
                                            <h5>{ user.name }</h5>
                                            </div>
                                        </DropdownItem>
                                        <div className="userbox">
                                            <ul className="list-unstyled mb-0">
                                                <DropdownItem tag="li" className="media">
                                                    <NavLink activeClassName="active" to="/dashboard" className="profile-icon"><img src={require("../assets/images/svg-icon/dashboard.svg").default} className="img-fluid" alt="dashboard" /><FormattedMessage id="DASHBOARD" /></NavLink>
                                                </DropdownItem>
                                                <DropdownItem tag="li" className="media">
                                                    <NavLink activeClassName="active" to="/profile" className="profile-icon"><img src={require("../assets/images/svg-icon/basic.svg").default} className="img-fluid" alt="profile" /><FormattedMessage id="PROFILE" /></NavLink>
                                                </DropdownItem>
                                                <DropdownItem tag="li" className="media">
                                                    <a href="#" onClick={logout} className="profile-icon"><img src={require("../assets/images/svg-icon/logout.svg").default} className="img-fluid" alt="logout" /><FormattedMessage id="LOGOUT" /></a>
                                                </DropdownItem>
                                            </ul>
                                        </div>
                                    </DropdownMenu>
                                </AutoDropDown>
                            </div>                                   
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>;
}

export class AutoDropDown extends React.Component {
    state = { active: false };

    render() {
        return <Dropdown isOpen={this.state.active} toggle={a => this.setState({ active: !this.state.active })}>
            { React.cloneElement(this.props.children[0], { href: "#", onClick: e => {
                e.preventDefault();
                
            } }) }
            {this.props.children[1]}
        </Dropdown>;
    }
}
